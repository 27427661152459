import React from "react";
import { translate } from "react-i18next";

const Footer = (props) => {
  // const { t } = props;
  return (
    <footer className="footer footer-dark">
      <div className="container">
        <div className="columns">
          <div className="column"></div>
          <div className="column"></div>
          <div className="column"></div>
          <div className="column">
            <div className="footer-column">
              <div className="footer-header">
                <h3>Credits</h3>
              </div>
              <ul className="link-list">
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://undraw.co/"
                  >
                    Undraw
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://pixel-me.tokyo/en/"
                  >
                    PixelMe
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="column">
            <div className="footer-column">
              <div className="footer-header">
                <ul style={{ marginBottom: "30px" }} className="link-list">
                  <li style={{ marginBottom: "0.5rem", fontWeight: 700 }}>
                    Veritable © {new Date().getFullYear()}
                  </li>
                  <li>
                    <a
                      href="https://bulma.io"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://bulma.io/images/made-with-bulma.png"
                        alt="Made with Bulma"
                        width="128"
                        height="24"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="content has-text-centered">
      <p>
        Veritable.pw © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://nextjs.org/">Next.js</a>
      </p>
    </div> */}
    </footer>
  );
};

export default translate()(Footer);
