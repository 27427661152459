import React from "react";
import { Link } from "gatsby";

import { translate } from "react-i18next";

import veritableLogo from "../images/veritable-logo.png";
import LanguageSwitcher from "./languageSwitcher";

class Navbar extends React.Component {
  componentDidMount() {
    const $navbarBurgers = Array.prototype.slice.call(
      document.querySelectorAll(".navbar-burger"),
      0
    );
    if ($navbarBurgers.length > 0) {
      $navbarBurgers.forEach(el => {
        el.addEventListener("click", () => {
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          el.classList.toggle("is-active");
          $target.classList.toggle("is-active");
        });
      });
    }
  }

  render() {
    const { t, navClasses } = this.props;
    return (
      <nav className={`navbar ${navClasses}`}>
        <div className="container">
          <div className="navbar-brand">
            <a className="navbar-item logo" href="/">
              <img src={veritableLogo} alt="veritable-logo" />
            </a>
            <LanguageSwitcher />
            <div className="navbar-burger burger" data-target="navbarMenuHeroA">
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          </div>
          <div id="navbarMenuHeroA" className="navbar-menu">
            <div className="navbar-end">
              <Link className="navbar-item" to="/" key="home">
                {t("home")}
              </Link>
              <Link className="navbar-item" to="/projects" key="projects">
                {t("projects")}
              </Link>
              <Link className="navbar-item" to="/about" key="projects">
                {t("about")}
              </Link>
              <a className="navbar-item" href="https://blog.veritable.pw">
                Blog
              </a>
              <span className="navbar-item">
                <a
                  className="button is-info"
                  href={t("contact url")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("contact")}
                </a>
              </span>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default translate("navbar")(Navbar);
