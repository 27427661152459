import React, { Component } from "react";
import { translate } from "react-i18next";

class LanguageSwitcher extends Component {
  constructor(props) {
    super(props);
    const { i18n } = this.props;
    this.state = { language: i18n.language };
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ language: nextProps.i18n.language });
  }

  handleChangeLanguage(lng) {
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
  }

  renderLanguageChoice({ code, label }) {
    if (
      this.state.language === code ||
      (this.state.language.startsWith("en") && code === "en") ||
      (this.state.language.startsWith("zh") && code === "zh")
    )
      return;
    return (
      <button
        className="button is-small is-primary"
        key={code}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          this.handleChangeLanguage(code);
        }}
      >
        {label}
      </button>
    );
  }

  render() {
    const languages = [
      { code: "en", label: "EN" },
      { code: "zh", label: "中文" },
    ];
    return (
      <div className="LanguageSwitcher">
        {languages.map((language) => this.renderLanguageChoice(language))}
      </div>
    );
  }
}

export default translate()(LanguageSwitcher);
