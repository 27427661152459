import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";
import { translate } from "react-i18next";

import veritableLarge from "../images/veritable-large.jpg";

const Meta = (props) => {
  const { t } = props;
  return (
    <StaticQuery
      query={graphql`
        query helmetQuery {
          site {
            siteMetadata {
              keywords
            }
          }
        }
      `}
      render={(data) => (
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
          />
          <meta
            name="google-site-verification"
            content="KPMdluEPolcDvgEEPcX8U8nNDn-0Z9yFDeYLMYQgzso"
          />
          <meta name="description" content={t("subtitle")} />
          <meta name="keywords" content={data.site.siteMetadata.keywords} />
          <title>{t("meta-title")}</title>
          <html lang="en" />
          {/* Google / Search Engine Meta Tags */}
          <meta itemprop="name" content={t("author")} />
          <meta itemprop="description" content={t("subtitle")} />
          <meta itemprop="image" content={veritableLarge} />
          <script type="text/javascript">{`window.$crisp = [];
            window.CRISP_WEBSITE_ID = "03ba9b0b-a510-4b1d-8fda-764fd5c30bea";
            (function() {
              d = document;
              s = d.createElement("script");
              s.src = "https://client.crisp.chat/l.js";
              s.async = 1;
              d.getElementsByTagName("head")[0].appendChild(s);
            })();
            `}</script>
          <meta property="test" content="test" />
        </Helmet>
      )}
    />
  );
};

export default translate()(Meta);
