import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";
import XHR from "i18next-xhr-backend";

export const options = {
  fallbackLng: {
    "zh-Hans": ["zh", "en"],
    "zh-Hant": ["zh", "en"],
    "zh-TW": ["zh", "en"],
    "zh-HK": ["zh", "en"],
    "zh-CN": ["zh", "en"],
    default: ["en"]
  },
  // have a common namespace used around the full app
  ns: ["common", "navbar", "projects", "midsection"],
  defaultNS: "common",
  debug: false,
  interpolation: {
    escapeValue: false // not needed for react!!
  },
  react: {
    wait: true
  },
  backend: {
    loadPath: "/locales/{{lng}}/{{ns}}.json"
  },
  detection: {
    caches: ["localStorage"]
  }
};

// for browser use xhr backend to load translations and browser lng detector
i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init(options);

export default i18n;
